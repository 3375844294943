<template>
  <div class="main-wrapper">
    <Header/>
    <div class="flex-grow-1">
      <v-container class="justify-center">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <div class="flex-grow-1 mt-12 elevation-2 rounded white py-6 px-6" style="max-width: 500px;">
              <v-form ref="resetForm" v-model="resetFormValid" class="mb-2">
                <h1 class="text-h4 text-center mb-6">{{ 'resetPassword'|localize }}</h1>
                <h6 class="text-subtitle-2 mb-2">{{ 'password'|localize }} *</h6>
                <v-text-field
                  v-model="password"
                  type="password"
                  dense
                  outlined
                  background-color="white"
                  :placeholder="'enterPassword'|localize"
                  :rules="[validationRules.required]"
                  autocomplete="new-password" :name="`new_password_${Math.random()}`"
                />
                <h6 class="text-subtitle-2 mb-2">{{ 'confirmPassword'|localize }} *</h6>
                <v-text-field
                  v-model="confirmPassword"
                  type="password"
                  dense
                  outlined
                  background-color="white"
                  :placeholder="'enterPassword'|localize"
                  :rules="[passwordRepeatRule]"
                  autocomplete="new-password" :name="`new_password_${Math.random()}`"
                />
              </v-form>
              <v-btn block color="primary" depressed rounded :loading="resetLoading" :disabled="resetLoading" @click="resetPassword">
                {{ 'save'|localize }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  import validationRules from '@/helpers/validationRules';
  import Header from '@/components/common/Header';
  import localize from '@/filters/localize';
  import methods from '@/helpers/methods';
  import api from '@/api/api';

  export default {
    name: "ResetPassword",
    components: {Header},

    data() {
      return {
        validationRules,
        resetFormValid: false,
        resetLoading: false,
        email: this.$router.currentRoute.query.email,
        token: this.$router.currentRoute.query.token,
        password: '',
        confirmPassword: ''
      };
    },

    computed: {
      passwordRepeatRule: function () {
        return this.password === this.confirmPassword || localize('passwordNotMatch');
      },
    },

    methods: {
      queryData() {
        let queryData = {};
        queryData.token = this.token;
        queryData.email = this.email;
        queryData.password = this.password;
        queryData.password_confirmation = this.confirmPassword;
        return queryData;
      },
      async resetPassword() {
        if (!this.$refs.resetForm.validate()) return;
        try {
          this.resetLoading = true;
          await api.resetPassword(this.queryData());
          methods.showGlobalSnackbar('success', localize('done'));
          this.$router.replace({name: 'login'});
        } catch (e) {
          this.resetLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      }
    }
  };
</script>
